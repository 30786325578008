// .js-submit-with-disable が付いた button に data-disable-with を自動で設定
// (すべての button を対象に自動設定したいが、影響範囲が大きいのでクラスにより手動付与)
$(function() {
  $(document).on('click', '.js-submit-with-disable', function() {
    if (this.type === 'submit') {
      if (!$(this).data('disable-with')) {
        $(this).attr('data-disable-with', $(this).html());
      }
    } else {
      this.disabled = true
    }
  })
});
