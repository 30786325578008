import 'bootstrap'
import '@popperjs/core'

// 古いブラウザ対策
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'fetch-polyfill'

import '../scripts/application/lib'

// app/javascript/scripts/commons
// ・通常のjavascriptをロードします。
// ・全ページでロードされるためご注意ください。
// ・特定のページでしかロードされないファイルを作りたい場合は、別途packsを定義してください。
const requireContext = require.context('../scripts/application', true)
requireContext.keys().forEach(requireContext)

// app/javascript/scripts/components
// ・React関連のファイルをロードします
const componentRequireContext = require.context('../components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
